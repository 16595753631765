import { TTheme } from "../models/types/Theme.types";

export const SITE_NAME = process.env.REACT_APP_SITE_NAME as string;
export const SITE_URL = process.env.REACT_APP_SITE_URL as string;
export const SITE_DESCRIPTION = process.env
  .REACT_APP_SITE_DESCRIPTION as string;
export const SITE_FAVICON = process.env.REACT_APP_SITE_FAVICON as string;
export const SITE_LOGO = process.env.REACT_APP_SITE_LOGO as string;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
export const BACKEND_URL_PROTOCOL = process.env
  .REACT_APP_BACKEND_URL_PROTOCOL as string; // http:// or https://
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string; // Without https:// or http:// prefix
export const API_VERSION = process.env.REACT_APP_API_VERSION as string;
export const THEME: TTheme = "default";
export const BACKEND_URL_INVENTORY = process.env
  .REACT_APP_BACKEND_URL_INVENTORY as string;
